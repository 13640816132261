import React from 'react'
import { Container, Hero } from 'react-bulma-components'
import { StaticImage } from 'gatsby-plugin-image'

function LatestArticle() {
  return (
    <Hero className="is-small is-hidden">
      <Hero.Body>
        <Container>
          <div className="columns content is-vcentered">
            <div className="column is-6 is-offset-1">
              <h1 className="title is-size-1-desktop is-size-2-tablet is-size-3-mobile">
                Recent <span className="has-text-primary">articles</span> about
                mobile.
              </h1>
            </div>
            <div className="column is-4">
              <StaticImage
                src="https://www.datocms-assets.com/20955/1579120516-blog.png?fm=jpg&w=450"
                alt="Latest Articles - Pocketworks"
              />
            </div>
          </div>
        </Container>
      </Hero.Body>
    </Hero>
  )
}

export default LatestArticle
