import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'

function BlogNavlinks({ activeLink }) {
  return (
    <div className="tabs is-medium is-centered">
      <ul>
        <li className={`${activeLink === 'latest' ? 'is-active' : ''}`}>
          <Link to="/blog/">Latest</Link>
        </li>
        <li className={`${activeLink === 'strategy' ? 'is-active' : ''}`}>
          <Link to="/blog/categories/get-results-with-mobile">Strategy</Link>
        </li>
        <li className={`${activeLink === 'design' ? 'is-active' : ''}`}>
          <Link
            to="/blog/categories/improve-your-customer-experience"
            activeClassName="is-active"
          >
            Design
          </Link>
        </li>
        <li className={`${activeLink === 'technology' ? 'is-active' : ''}`}>
          <Link to="/blog/categories/understand-the-tech">Technology</Link>
        </li>
        <li className={`${activeLink === 'pocketlife' ? 'is-active' : ''}`}>
          <Link to="/blog/categories/get-to-know-pocketworks">PocketLife</Link>
        </li>
      </ul>
    </div>
  )
}
BlogNavlinks.propTypes = {
  activeLink: PropTypes.string,
}
export default BlogNavlinks
