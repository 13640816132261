import PropTypes from 'prop-types'
import React from 'react'

// Components
import Layout from 'components/layout'
import SiteNav from 'components/navbar'
import AllBlogs from 'components/allBlogs'

// Page Componnets
import BlogNavLinks from './components/blog-nav-links'
import LatestArticle from './components/latest-article'

function BlogPage({ data: { posts } }) {
  return (
    <Layout>
      <SiteNav fullLogo />
      <BlogNavLinks activeLink="latest" />
      <LatestArticle />
      <AllBlogs posts={posts} />
    </Layout>
  )
}

BlogPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BlogPage
